// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media all and (min-width: 768px) {
  .bs-app-wrap {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media all and (min-width: 900px) {
  .bs-app-wrap {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.bs-app .loading {
  opacity: 0.4;
  z-index: 100;
}

.bs-search-body {
  display: flex;
}
.bs-search-body .left {
  width: 20vw;
  margin-right: 16px;
  overflow: hidden;
}
.bs-search-body .right {
  width: 80vw;
}

.bs-actions {
  display: flex;
  justify-content: space-between;
  margin: 0 0 15px;
}
.bs-actions .bs-result-message {
  color: #878787;
  font-size: 24px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./Static/bravo-react-search/src/styles/app.scss"],"names":[],"mappings":"AAGI;EACI;IAAG,kBAAA;IAAmB,mBAAA;EAA5B;AACF;AACI;EACI;IAAG,iBAAA;IAAkB,kBAAA;EAG3B;AACF;;AACI;EACI,YAAA;EACA,YAAA;AAER;;AAEA;EACI,aAAA;AACJ;AAAI;EACI,WAAA;EACA,kBAAA;EACA,gBAAA;AAER;AAAI;EACI,WAAA;AAER;;AAEA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AACJ;AAAI;EACI,cAAA;EACA,eAAA;EACA,iBAAA;AAER","sourcesContent":["@import \"_variables\";\n\n.bs-app-wrap {\n    @media all and (min-width:$screen-desk-min) {\n        & {padding-left:15px; padding-right:15px;}\n    }\n    @media all and (min-width:$timber-desk-min) {\n        & {padding-left:0px; padding-right:0px;}\n    }\n}\n\n.bs-app{\n    .loading{\n        opacity:0.4;\n        z-index: 100;\n    }\n}\n\n.bs-search-body{\n    display: flex;\n    .left{\n        width: 20vw;\n        margin-right: 16px;\n        overflow: hidden;\n    }\n    .right{\n        width: 80vw;\n    }\n}\n\n.bs-actions{\n    display: flex;\n    justify-content: space-between;\n    margin:0 0 15px;\n    .bs-result-message {\n        color: $itc-dark-gray-2;\n        font-size:24px;\n        font-weight:bold;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
