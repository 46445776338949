import React from 'react'
import { termHighlighter, snippet, dateGetPartial } from './utils'


function DocumentPG(props){
    const {summary, name, url, date_na_dt, status, searchTerm} = props
    const formattedSummary = termHighlighter(snippet(summary), searchTerm)
    const datePartial = dateGetPartial(date_na_dt)

    var statusStr = status;

    if (status instanceof Array) {
        statusStr = status.join(", ");
    }

    return (
        <a className="search-document" href={url}>
            <div className="title">{name}</div>
            <div className="status">
                {(statusStr) && (<span>Status: {statusStr}</span>)}
                <div><span>Date: {datePartial}</span></div>
            </div>
            <div className="body" dangerouslySetInnerHTML={{__html: formattedSummary}}></div>
        </a>
    )
}

export default DocumentPG