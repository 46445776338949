import {combineReducers} from 'redux'
import {SearchReducer, MetricsReducer} from '@bravo/react-search'
import IDSAReducer from '../idsa/index'

export default combineReducers({
    search: SearchReducer,
    metrics: MetricsReducer,

    //rs: custom reducer
    idsaSuppAggData: IDSAReducer
})