'use strict';
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Utils} from '@bravo/react-search'

import App from './app'
import reducer from '../reducers/default'

export default function SearchTemplate({element, keys, telemetry}){
    Utils.store.createSearchStore(reducer, keys, {telemetry}).then(store => {
        ReactDOM.render(<Provider store={store}><App st={store} category={keys['category']} resourceId={keys['resourceId']} telemetry={telemetry}/></Provider>, element)
    })
}