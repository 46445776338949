import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import '../../styles/facets.scss'

const areAnyFacetsGroupItemsSelected = (facets, fullFacetGroup) => {
    
    var matchingFacetGroup = facets.selected[fullFacetGroup.id];

    if(!!matchingFacetGroup)
        return true;
    
    return false;
};

const isFacetGroupItemSelected = (facets, fullFacetGroup, fullFacetGroupItems) => {

    var matchingFacetGroup = facets.selected[fullFacetGroup.id];

    if(!!!matchingFacetGroup)
        return false;
    
    return matchingFacetGroup.some(selVal => selVal === fullFacetGroupItems.key);
};

const DropDown = (props) => {

    const { facets, onFacetTermToggle, onFacetClear } = props;

    if(facets.groups.length === 0)
        return null;

    const [fullFacets, setFullFacets] = React.useState(facets);

    const onFacetChange = (event) => {

        event.preventDefault();

        onFacetClear();

        if (!event.target.hash || !event.target.hash.length || event.target.hash === "#all")
            return;

        const facetValues = event.target.hash.substring(1).split('+');

        if (facetValues.length < 2)
            return;

        const facetId = facetValues[0];
        const currentFacet = facets.groups.find(facet => facet.id === facetId);

        if (!currentFacet)
            return;

        const termId = facetValues[1];

        if (!termId)
            return;

        const term = { key: termId, title: termId };

        onFacetTermToggle(term, currentFacet);
    }

    return (
        <div>
            {fullFacets.groups.map(fullFacetGroup => {
                    return (
                        <div key={fullFacetGroup.id} className="btn-group" role="group">
                            <button id={`btn+${fullFacetGroup.id}`} type="button" className="btn btn-group-drop dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Category</button>
                            <div className="dropdown-menu" aria-labelledby={`btn+${fullFacetGroup.id}`}>
                                <a key="all" className={clsx("dropdown-item", !areAnyFacetsGroupItemsSelected(facets, fullFacetGroup) && "selected")} href="#all" onClick={onFacetChange}>All</a>
                                    {fullFacetGroup.items.map(fullFacetGroupItem => {
                                            return (
                                                <a key={fullFacetGroupItem.key} className={clsx("dropdown-item", isFacetGroupItemSelected(facets, fullFacetGroup, fullFacetGroupItem) && "selected")} href={`#${fullFacetGroup.id}+${fullFacetGroupItem.key}`} onClick={onFacetChange}>{fullFacetGroupItem.key}</a>
                                            )
                                        })
                                    }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

DropDown.propTypes = {
    facets: PropTypes.shape({
        selected: PropTypes.object,
        groups: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            items: PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ]),
                docCount: PropTypes.number,
                selected: PropTypes.bool,
                title: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ])
            })),
            title: PropTypes.string
        }))
    }).isRequired,
    onFacetTermToggle: PropTypes.func.isRequired,
    onFacetClear: PropTypes.func.isRequired
}

export default DropDown