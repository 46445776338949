
import React from 'react'
import { termHighlighter, snippet, dateGetPartial } from './utils'

const Blog = ({url, title, subtitle, listingImage_na_str, authorNames_na_str, publishedDate_na_dt, summary, description, searchTerm, showImages, body}) => {
    const formattedSummary = summary ? termHighlighter(snippet(summary), searchTerm) : termHighlighter(snippet(body), searchTerm );
    const authorNamesString = (authorNames_na_str && Array.isArray(authorNames_na_str)) ? authorNames_na_str.join(', ') : authorNames_na_str;

    return(
        <a className="search-document blog-document" href={url}>
            <div className="d-flex justify-content-between">
                {(listingImage_na_str && showImages) && <div className="blog-list-thumbnail"><img src={listingImage_na_str}/></div>}
                <div>
                    <div className="title">{title}</div>
                    {subtitle && <div className="blog-subtitle">{subtitle}</div>}
                    <div className="status">
                        <div className="blog-author">{authorNamesString}</div>
                        <span>Published: { (new Date(publishedDate_na_dt)).toLocaleDateString() }</span>
                    </div>
                    <div className="body">{formattedSummary}</div>
                </div>                    
            </div>
        </a>
    )
}

export default Blog
