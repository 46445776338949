
import React from 'react'

const BravoBlockItem = ({url, title, isrtln}) => {
    if(isrtln === "True"){
        return(
            <a className="rss-feed-listing d-flex justify-content-between" href={url}>
                <span className="title">{title}</span>
                <div className="rss-chevron">
                    <i class="fas fa-chevron-right"></i>
                </div>                
            </a>
        )
    } else {
        return(
            <a className="list-group-item list-group-item-action rounded-0 flex-column align-items-start list-item" href={url}>
                <div className="title">
                    {title}
                </div>            
            </a>
        )
    }

}

export default BravoBlockItem