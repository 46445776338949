import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

function ResultsMessage(props){
    const {total, searchTerm} = props
    const hasSearchTerm = searchTerm !== '';
    return (
        <div>Search returned {`${total} result${(total > 1) ? "s" : ""} `}<span className={clsx(!hasSearchTerm && "d-none")}>for "{searchTerm}"</span></div>
    )
}

ResultsMessage.propTypes = {
    total: PropTypes.number.isRequired,
    searchTerm: PropTypes.string
}

export default ResultsMessage 