import React from 'react'
import PropTypes from 'prop-types'

import '../../styles/tabs.scss'

const Tabs = (props) => {
    const {tab, onTabSelect, onTabClear} = props
    const {facet, selected} = tab
    if(Object.keys(facet).length === 0){
        return null
    }
    return (
        <div className="bs-tab-container">
            <ul>
                {(facet.default === "") && (
                    <li 
                        onClick={() => onTabClear()}
                        className={(selected.length === 0) ? 'selected' : ''}
                    >
                        <span>All</span>
                    </li>
                )}                
                {facet.items.map(i => {
                    if(i.docCount === 0){
                        return (
                            <li 
                                key={i.key}
                                className={i.selected ? 'selected' : 'disabled'}
                            >
                                <span>{i.title}</span>
                            </li>
                        )
                    }
                    return (
                        <li
                            key={i.key}
                            className={(i.selected) ? 'selected': ''}
                            onClick={() => onTabSelect(i.key)}
                        >
                            <span>{i.title}</span>
                            <span>{i.docCount}</span>
                        </li>
                    )
                })}
            </ul>      
        </div>
    )
}
Tabs.propTypes = {
    onTabSelect: PropTypes.func,
    tab: PropTypes.shape({
        facet:PropTypes.shape({
            items: PropTypes.array
        })
    })
}
export default Tabs