import React from 'react'
import PropTypes from 'prop-types'

import '../../styles/paging.scss'

const ViewMore = (props) => {
    const {paging, onPageNext} = props
    const {pageIndex, total} = paging

    // console.log("paging: ", paging)

    if(pageIndex >= total - 1){
        return (
            <div className="bs-paging"></div>
        )
    }
    return (        
        <div className="bs-paging">
            <div className="bs-paging-button" onClick={onPageNext}>+ MORE</div>
        </div>
    )
}

ViewMore.propTypes = {
    paging: PropTypes.shape({
        total: PropTypes.number.isRequired
    }).isRequired,
    onPageNext: PropTypes.func.isRequired
}

export default ViewMore