import * as React from 'react'
import PropTypes from 'prop-types'
import Spellcheck from '../Spellcheck'

import '../../styles/searchInput.scss'

const SearchInput = (props) => {
    const {onKeywordChange, onKeywordKeyDown, onKeywordSubmit, keyword, placeholder} = props
    const {searchTerm, suggestions, value} = keyword
    const inputEl = React.useRef(null)
    return (
        <div className="col-xs-12 results-input input-group d-flex">
            <span className="twitter-typeahead">
                <input className="p-1 rounded-0 search-input typeahead tt-input" 
                    ref={inputEl}
                    onChange={onKeywordChange}
                    onKeyDown={onKeywordKeyDown}
                    value={value} 
                    placeholder={placeholder}
                />
            </span>
            <div className="input-group-append" onClick={onKeywordSubmit}>
                <button className="btn btn-primary py-1 px-2 rounded-0 search-submit" type="button">
                    <i className="fas fa-search fa-xs"></i>
                    <span className="sr-only">Search</span>
                </button>
            </div>
        </div>
    )
}

SearchInput.propTypes = {
    onKeywordChange: PropTypes.func,
    onKeywordKeyDown: PropTypes.func,
    onKeywordSubmit: PropTypes.func,
    keyword: PropTypes.object.isRequired
}

export default SearchInput