import React from 'react'
import PropTypes from 'prop-types'
import {SearchActions} from '@bravo/react-search'
import { onPageNext } from '@bravo/react-search/src/Search/actions'

const ViewAllButton = (props) => {
    const {st, setUsePagination, onPageChange} = props

    const viewAll = (evt) => {
        evt.preventDefault()

        const search = st.getState().search
        const settings = search.settings

        console.log('settings', settings);
        setUsePagination(false);
        
        const newSettings = {
			...settings,
			query: {
				...settings.query,
                pageSize: 100
			}
		};

        console.log("New Settings: ", newSettings);

		st.dispatch(SearchActions.settingsChange(newSettings));
        onPageChange(0);
    }

    return (
        <div className="btnViewAll">
            <a href="#" onClick={viewAll} className="btn btn-primary" data-feathr-click-track="true">View All</a>
        </div>        
    )
}

ViewAllButton.propTypes = {
    st: PropTypes.any.isRequired,
    setUsePagination: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired
}

export default ViewAllButton
