import React from 'react'
import ClickHandler from './clickHandler'
import { getOupRedirectUrl, getSourceDisplay } from './utils'


function DocumentBasic(props){
    //rs: added extra prop for date_na_dt
    const {body, title, url, description, type, format, date_na_dt, onClick, source, isMember, oupSsoPrefix, membersOnly_na_bool} = props
    const sourceDisplay = getSourceDisplay(source)
    const finalUrl = type === 'Scrapy' ? getOupRedirectUrl(url, oupSsoPrefix) : url
    const showMemberBadge = isMember === 'false' && membersOnly_na_bool === 'true'

    return (
        <a className="search-document" href={finalUrl}>
            <div className="badge-header">
                {(showMemberBadge) && 
                    (<div className="badge members-only">Members Only</div>)}
                <div className="badge">
                    {format}
                </div>
            </div>
            <div className="sub-header">
                {/* rs: format the date to make it pretty for humans */}
                <span>Published: { (new Date(date_na_dt)).toLocaleDateString() }</span>
            </div>
            <div className="title">{title}</div>
            <div className="body">
                <p>{description || body}</p>
            </div>
            <div className="footer">
                <div className="badge">{sourceDisplay}</div>
            </div>
        </a>
    )
}

export default DocumentBasic