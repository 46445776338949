// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-suggest-group {
  cursor: pointer;
}
.bs-suggest-group i {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./Static/bravo-react-search/src/styles/spellcheck.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AAAI;EACI,0BAAA;AAER","sourcesContent":[".bs-suggest-group {\n    cursor: pointer;\n    i {\n        text-decoration: underline;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
