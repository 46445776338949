import * as IDSAActionTypes from '../../actions/idsa/actionTypes'

function topics(state = {list: [], fetched: true}, action){

    if(action.error){
        return state
    }
    switch(action.type){
        case IDSAActionTypes.IDSA_TOPICS_REQUEST_COMPLETE:
            const {response} = action.payload
            return {list: response, fetched: true}
    }
    return state
}

export default topics