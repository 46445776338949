import * as React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Search, Metrics, SearchActions, MetricsActions} from '@bravo/react-search'

import Documents from '../../components/Documents'
import PagingWithNumbers from '../../components/PagingWithNumbers'
import SearchInput from '../../components/SearchInput'
import Sort from '../../components/Sort'
import ResultsMessage from '../../components/ResultsMessage'
import NoResults from '../../components/NoResults'

import "../../styles/app.scss"

const mapStateToProps = (state, ownProps) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        searchActions: bindActionCreators(SearchActions, dispatch),
        metricsActions: bindActionCreators(MetricsActions, dispatch),
    }
}

const App = (props) => {

    // console.log("Redux Store/Actions:", props);

    const {search, searchActions, isMember, oupSsoPrefix, st, metrics, metricsActions} = props
    const {trackQueryEvent} = metricsActions

    const {ui, documents, keyword, paging, settings, sort, tab} = search
    const {pageSize} = settings.query
    const {isFetching} = ui
    const hasDocuments = documents.list.length > 0

    const {onHashChange, refreshCancel, searchInitialize, searchSubmit} = searchActions

    const {onKeywordChange, onKeywordKeyDown, onKeywordSubmit, onPageNext, onPagePrev, onPageChange} = searchActions
    const {onSortChange} = searchActions

    const bodyEl = React.useRef(null)
    React.useEffect(() => {
        //no auto-scroll on initial page load
        if(bodyEl.current.getBoundingClientRect().top < 0) {
            bodyEl.current.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"})
        }
    }, [documents]);
    return (
        <div ref={bodyEl} className={(isFetching) ? "bs-app loading" : "bs-app"}>
            <Metrics 
                {...metrics}
                trackQueryEvent={trackQueryEvent}
            />
            <Search 
                onHashChange={onHashChange}
                refreshCancel={refreshCancel}
                search={search}
                searchInitialize={searchInitialize}
                searchSubmit={searchSubmit}
                deepLinking={false}
            /> 
            {(hasDocuments) ? (
                <div className="row">
                    <div className="bravoSearchResults">
                        <div className="col-12 search-columns">
                            <div className="rtln_body_content">
                                <div>
                                <div className="d-lg-none mobile-results-header">
                                    <div className="col-12 results-summary">
                                        <ResultsMessage 
                                            total={documents.total}
                                            searchTerm={keyword.searchTerm}
                                        />
                                    </div>
                                    <SearchInput
                                        keyword={keyword}
                                        onKeywordChange={onKeywordChange}
                                        onKeywordKeyDown={onKeywordKeyDown}
                                        onKeywordSubmit={onKeywordSubmit}
                                    />
                                </div>
                                <div className="results-header">
                                    <div className="results-summary d-none d-lg-block">
                                        <ResultsMessage 
                                            total={documents.total}
                                            searchTerm={keyword.searchTerm}
                                        />
                                    </div>
                                    <Sort
                                        sort={sort}
                                        onSortChange={onSortChange}
                                    />
                                </div>
                                <Documents
                                    documents={documents}
                                    onDocumentClick={searchActions.onDocumentClick}
                                    isMember={isMember}
                                    oupSsoPrefix={oupSsoPrefix}
                                />
                            </div>
                                {(hasDocuments) && (
                                    <PagingWithNumbers
                                        paging={paging}
                                        onPageNext={onPageNext}
                                        onPagePrev={onPagePrev}
                                        onPageChange={onPageChange}
                                        maxDisplayCount={pageSize}
                                        totalDocumentCount={documents.total}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row justify-content-center">
                    <div className="col-12">
                        <NoResults 
                            st={st}
                            searchInitialize={searchInitialize}
                            searchSubmit={searchSubmit}
                            isFetching={isFetching}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
