export function relativeToAbsoluteUrl(orig){
    if(!orig || orig.startsWith("http")){
        return orig
    }
    switch(window.location.host){
        case "localhost:3000":
        case "bravosquaredp2.blob.core.windows.net":
            return `https://intrustprod.azurewebsites.net${orig}`
        default:
            return orig
    }
}

export function thumbnailUrl(orig){
    if(!orig){
        return "https://cdn.bravosquared.com/intrust/thumbnail-default.png"
    }
    return relativeToAbsoluteUrl(orig)
}

export function buildArticleUrl(issueName, articleName){
    if(!issueName || !articleName){
        return "/Magazine"
    }
    const parseName = (name) => {
        return name.split(/\W/).filter(Boolean).join("-")
    }
    const result = `/Magazine/Issues/${parseName(issueName)}/${parseName(articleName)}`
    return relativeToAbsoluteUrl(result)
}

export function getSourceDisplay(source) {
    switch (source) {
        case 'hivma':
            return 'HIVMA.org'
        case 'idsociety':
            return 'IDSociety.org'
        case 'rtln':
            return 'COVID-19 Real-Time Learning Network'
        default:
            return source
    }
}

export function getOupRedirectUrl(url, oupRedirectUrl) {
    if(url && oupRedirectUrl) {
        return oupRedirectUrl.replace(/&amp;/g, '&') + encodeURIComponent(url)
    }
    return url
}

export function termHighlighter(str, term) {
    if (!str) return ""
    if (!term) return str
    if(Array.isArray(str)) {
        str = str.join(' ');
    }
    var regex = new RegExp(term, 'gi')
    return str.replace(regex, function (a, b) {
        return '<span class="highlight">' + a + '</span>'
    })
}

export function snippet(str, length){

    if(!length)
        length = 255
        
    if(!str) return ''
    if(Array.isArray(str)) {
        str = str.join(' ')
    }
    if(str.length < length) {
        return str
    }
    return str.substr(0, length) + '...'
}

export function dateGetPartial(str){
    if (!str) return "";
    var date = new Date(str);
    return (date.getMonth()+1) + '/' + date.getFullYear();
}