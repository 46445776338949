import * as React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Search, Metrics, SearchActions, MetricsActions} from '@bravo/react-search'

import Documents from '../../components/Documents'
import Facets from '../../components/Facets'
import PagingWithNumbers from '../../components/PagingWithNumbers'
import Ranges from '../../components/Ranges'
import SearchInput from '../../components/SearchInput'
import FacetTabs from '../../components/Facets/tabs'
import ResultsMessage from '../../components/ResultsMessage'
import ViewAllButton from '../../components/ViewAllButton'
import NoResults from '../../components/NoResults'
import Modal from '../../components/Facets/modal'
import clsx from 'clsx'

import "../../styles/app.scss"
import { onPageNext, settingsChange } from '@bravo/react-search/src/Search/actions'

const mapStateToProps = (state, ownProps) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        searchActions: bindActionCreators(SearchActions, dispatch),
        metricsActions: bindActionCreators(MetricsActions, dispatch),
    }
}

const App = (props) => {

    console.log("Redux Store/Actions:", props);

    const {search, searchActions, idsaSuppAggData, isMember, oupSsoPrefix, st, metrics, metricsActions} = props
    const {trackQueryEvent} = metricsActions

    const {ui, documents, facets, keyword, paging, ranges, settings, tab} = search
    const {pageSize} = settings.query;
    const {isFetching} = ui
    const hasDocuments = documents.list.length > 0
    const hasFacetSelections = Object.keys(facets.selected).length > 0

    const {onHashChange, refreshCancel, searchInitialize, searchSubmit} = searchActions

    const {onKeywordChange, onKeywordKeyDown, onKeywordSubmit, onPageNext, onPagePrev, onPageChange} = searchActions
    const {onTabSelect, onFacetClear, onFacetTermToggle, onRangeChange, settingsChange} = searchActions
    const showPaginationAndViewAll = pageSize <= documents.total

    const [usePagination, setUsePagination] = React.useState(true);
    const [offset, setOffset] = React.useState(0);
    
    React.useEffect(() => {
        window.onscroll = () => {
            const pageYOffset =  window.pageYOffset;
            if ((pageYOffset + 200) <= offset) { return; }
            setOffset(pageYOffset)
            if (usePagination) { return; }
            console.log('document', documents);
            if (documents.list.length >= documents.total) { return; }
            onPageNext();
        }
        return () => window.onscroll = undefined
    }, [documents, usePagination]);


    const onFacetClearCustom = () => {
        onFacetClear()

        setUsePagination(true)

        const newSettings = {
			...settings,
			query: {
				...settings.query,
                pageSize: 12
			}
		};

        console.log("New Settings: ", newSettings);

		st.dispatch(SearchActions.settingsChange(newSettings));
        onPageChange(0);
    }

    const bodyEl = React.useRef(null)
    React.useEffect(() => {
        //only scroll if we are below the body el, this helps for initial page load
        //we do not want to scroll past ads on page load
        //if usePagination=false user is in view all mode so don't want to scroll to top on result loading
        if(bodyEl.current.getBoundingClientRect().top < 0 && usePagination) {
            bodyEl.current.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"})
        }
    }, [documents, usePagination]);
    return (
        <div ref={bodyEl} className={clsx((isFetching) ? "bs-app loading" : "bs-app", "mb-5")}>
            <Metrics 
                {...metrics}
                trackQueryEvent={trackQueryEvent}
            />
            <Search 
                onHashChange={onHashChange}
                refreshCancel={refreshCancel}
                search={search}
                searchInitialize={searchInitialize}
                searchSubmit={searchSubmit}
                deepLinking={false}
                //If using pagination need to go to next page instead of appending to current result set. 
                appendResults = {!usePagination}
            />
            
            {(hasDocuments) ? (
                <div className="bravoSearchResults">
                    <div className="row">
                        {(facets.groups.length > 1) && (
                            <div className="col-lg-4 col-xl-3 d-none d-lg-block">
                                <div id="bravoSearchFacetPanelLg" className="facet-panel-wrap">
                                    <div className="facets">
                                        <Facets
                                            facets={facets}
                                            onFacetTermToggle={onFacetTermToggle}
                                            suppAggData={idsaSuppAggData}
                                            docCount={documents.total}
                                        />
                                        <Ranges 
                                            onRangeChange={onRangeChange}
                                            ranges={ranges}
                                        />
                                        {(hasFacetSelections) && (
                                            <div className="clear-filters text-center">
                                                <a onClick={onFacetClearCustom} className="btn btn-primary search" data-feathr-click-track="true">Clear Filters</a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="col-lg-8 col-xl-9 search-columns">
                            <div>
                                <div className="d-lg-none mobile-results-header">
                                    <div className="col-12 results-summary">
                                        <ResultsMessage 
                                            total={documents.total}
                                            searchTerm={keyword.searchTerm}
                                        />
                                    </div>
                                    {(facets.groups.length > 1) && (<Modal 
                                        facets={facets}
                                        onFacetTermToggle={onFacetTermToggle}
                                        onRangeChange={onRangeChange}
                                        ranges={ranges}
                                        onFacetClear={onFacetClearCustom}
                                        suppAggData={idsaSuppAggData}
                                        docCount={documents.total}
                                    />)}
                                    <SearchInput
                                        keyword={keyword}
                                        onKeywordChange={onKeywordChange}
                                        onKeywordKeyDown={onKeywordKeyDown}
                                        onKeywordSubmit={onKeywordSubmit}
                                    />
                                </div>
                                <div className="d-none d-lg-block">
                                    <SearchInput 
                                        onKeywordChange={onKeywordChange}
                                        onKeywordKeyDown={onKeywordKeyDown}
                                        onKeywordSubmit={onKeywordSubmit}
                                        keyword={keyword}
                                        placeholder="Search Multimedia"
                                    />
                                </div> 
                                {(Object.keys(tab).length !== 0) && (
                                    <FacetTabs 
                                        onTabSelect={onTabSelect}
                                        tab={tab}
                                    />
                                )}
                                <Documents
                                    documents={documents}
                                    onDocumentClick={searchActions.onDocumentClick}
                                    isMember={isMember}
                                    isMediaTemplate={true}
                                    oupSsoPrefix={oupSsoPrefix}
                                    searchInitialize={searchInitialize}
                                    searchSubmit={searchSubmit}
                                    page={paging.pageIndex + 1}
                                />
                            </div>
                            {(hasDocuments && usePagination && showPaginationAndViewAll) && (
                                <div className="row">
                                <div className="col-12 paging-alt-2">
                                    <ViewAllButton 
                                        st={st}
                                        onPageChange={onPageChange}
                                        setUsePagination={setUsePagination}
                                    />
                                    <PagingWithNumbers
                                        paging={paging}
                                        onPageNext={onPageNext}
                                        onPagePrev={onPagePrev}
                                        onPageChange={onPageChange}
                                        maxDisplayCount={pageSize}
                                        totalDocumentCount={documents.total}
                                    />
                                </div>
                            </div>
                            )}
                            
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row justify-content-center">
                    <div className="col-12">
                    <NoResults
                        st={st}
                        searchSubmit={searchSubmit}
                        isFetching={isFetching}
                    />
                    </div>
                </div>
            )}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
