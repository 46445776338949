// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-date-range {
  min-width: 15vw;
  padding: 30px;
  border-radius: 8px;
  background: #F9F9F9;
}
.bs-date-range label {
  font-weight: bold;
}

.bs-date-range-body {
  display: flex;
  margin-bottom: 16px;
}
.bs-date-range-body input {
  padding: 15px;
  margin: 0 4px;
}

.bs-date-range-actions {
  display: flex;
  justify-content: space-around;
  margin: 8px;
}
.bs-date-range-actions .bs-button {
  flex-basis: 30px;
  width: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 10px;
  background: none;
  border: none;
  outline: none !important;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./Static/bravo-react-search/src/styles/ranges.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EAEA,aAAA;EACA,kBAAA;EACA,mBAAA;AADJ;AAGI;EACI,iBAAA;AADR;;AAKA;EACI,aAAA;EACA,mBAAA;AAFJ;AAII;EACI,aAAA;EACA,aAAA;AAFR;;AAMA;EACI,aAAA;EACA,6BAAA;EACA,WAAA;AAHJ;AAKI;EACI,gBAAA;EAAiB,WAAA;EAAY,YAAA;EAAa,cAAA;EAAe,kBAAA;EAAmB,gBAAA;EAAiB,YAAA;EAAa,wBAAA;EAAyB,eAAA;AAK3I","sourcesContent":["\n.bs-date-range {\n    min-width: 15vw;\n\n    padding: 30px;\n    border-radius: 8px;\n    background: #F9F9F9;\n\n    label{\n        font-weight: bold;\n    }\n}\n\n.bs-date-range-body{\n    display: flex;\n    margin-bottom: 16px;\n\n    input {\n        padding: 15px;\n        margin: 0 4px;\n    }\n}\n\n.bs-date-range-actions{\n    display: flex;\n    justify-content: space-around;\n    margin: 8px;\n\n    .bs-button {\n        flex-basis:30px; width:30px; flex-grow:0; flex-shrink:0; padding-left:10px; background:none; border:none; outline:none !important; cursor:pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
