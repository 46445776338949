import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import '../../styles/document.scss'

import DocumentBasic from './basic'
import DocumentPG from './pg'
import MediaDocument from './MediaDocument'
import Blog from './Blog'
import BravoBlockItem from './BravoBlockItem'
import RTLNCategoryDocument from './rtlnCategoryDocument'

const Documents = (props) => {
    const {documents, onDocumentClick, isMember, oupSsoPrefix, pgTemplate, isMediaTemplate, isBlogTemplate,
        isBravoBlockListing, isRTLNCategoryPage, isRTLN2, searchTerm, showImages, isrtln} = props
    const {list} = documents
    const onClick = () => {
        onDocumentClick(document)
    }

    return (
        <React.Fragment>
        <div className={isMediaTemplate ? "results-listing container" : "results-listing"}>
            <div className={clsx((isRTLNCategoryPage || isRTLN2) ? "row idsaContainerBlockContent" : "", isMediaTemplate ? "row media-grid" : "", isBravoBlockListing ? "list-group list-group-flush list-group-flush-1" : "")}>
                {list.map((doc, index) => {
                    if(isMediaTemplate){
                        if(index === 0 /*&& page === 1*/){
                            return(
                                <MediaDocument
                                    key={doc.id}
                                    {...doc}
                                    isMember={isMember}
                                    oupSsoPrefix={oupSsoPrefix}
                                    searchTerm={searchTerm}
                                    featured={true}
                                />
                            )
                        } else {
                            return(
                                <MediaDocument
                                    key={doc.id}
                                    {...doc}
                                    isMember={isMember}
                                    oupSsoPrefix={oupSsoPrefix}
                                    searchTerm={searchTerm}
                                    featured={false}
                                />
                            )
                        }
                    }
                    if(isBlogTemplate){
                        return(
                            <Blog
                                key={doc.id}
                                {...doc}
                                isMember={isMember}
                                oupSsoPrefix={oupSsoPrefix}
                                searchTerm={searchTerm}
                                showImages = {showImages}

                            />
                        )
                    }

                    if(pgTemplate) {
                        return (
                            <DocumentPG
                                key={doc.id}
                                {...doc}
                                isMember={isMember}
                                oupSsoPrefix={oupSsoPrefix}
                                searchTerm={searchTerm}
                            />
                        )
                    }

                    if(isBravoBlockListing){
                        return (
                            <BravoBlockItem
                                key={doc.id}
                                {...doc}
                                isMember={isMember}
                                oupSsoPrefix={oupSsoPrefix}
                                searchTerm={searchTerm}
                                isrtln={isrtln}
                            />
                        )
                    }

                    if(isRTLNCategoryPage || isRTLN2){
                        return (
                            <RTLNCategoryDocument
                                key={doc.id}
                                {...doc}
                                isMember={isMember}
                                oupSsoPrefix={oupSsoPrefix}
                            />
                        )
                    }

                    return (
                        <DocumentBasic
                            key={doc.id}
                            {...doc}
                            isMember={isMember}
                            oupSsoPrefix={oupSsoPrefix}
                            onClick={onClick}
                        />
                    )
                })}
            </div>
        </div>
        </React.Fragment>
    )
}
Documents.propTypes = {
    documents: PropTypes.shape({
        list: PropTypes.array
    }),
    onDocumentClick: PropTypes.func
}

export default Documents