import React from 'react'
import clsx from 'clsx'
import { termHighlighter, snippet, dateGetPartial } from './utils'

const RTLNCategoryDocument = ({url, title, publishedDate_na_dt, resourceType_na_str,
    listingImage, summary, body, resourceUrl, isExternalUrl, isCarouselItem}) => {
    const formattedSummary = summary ? summary : body
    const hasListingImage = (listingImage) ? true : false;
    const listingImageSrc = (hasListingImage) ? (((listingImage.indexOf('?') >= 0) ? listingImage.split('?')[0] : listingImage).trim()) : ""

    return (
        <div className={clsx(isCarouselItem === "true" ? "item" : "col-12 pb-4")}>
            <div className="link-block-wrap">
                <a className={clsx("link-block d-flex flex-column js-match-height justify-content-between", hasListingImage && "hasImage", isExternalUrl === "true" && "isExternalLink")} href={resourceUrl ?? url} target={isExternalUrl === "true" ? "_blank" : "_self"} data-feathr-click-track="true" data-feathr-link-aids="[&quot;5c75a5bf443202177804aed4&quot;]">
                    {hasListingImage &&
                    <div className="link-block-image">
                        <picture className="picture-align-x-center picture-align-y-top">
                            <source srcSet={listingImageSrc + '?width=378&height=210&format=webp&quality=100&rxy=0.4%2c0.19&rmode=crop'} media="(min-width: 992px)" />
                            <source srcSet={listingImageSrc + '?width=444&height=200&format=webp&quality=100&rxy=0.4%2c0.19&rmode=crop'} />
                            <img src={listingImageSrc + '?width=444&height=200&format=webp&quality=100&rxy=0.4%2c0.19&rmode=crop'} loading="eager" decoding="auto" />
                        </picture>
                    </div>
                    }

                    <div className="link-block-text">
                            <h3 className="link-block-title">{title.trim()}</h3>
                            <div className="link-block-body">
                                <p>{formattedSummary}</p>
                            </div>
                    </div>

                    <div className="link-lower d-flex justify-content-between" style={publishedDate_na_dt || resourceType_na_str ? {} : {borderTop: "none"}}>
                        <div className="link-icon">
                                    <span className="link-text">{resourceType_na_str}</span>
                                    <span className="icon-wrapper">
                                        <span className="icon icon-external-link"></span>
                                        <span className="behind-icon"></span>
                                    </span>
                        </div>
                        {(publishedDate_na_dt) ? (<time className="link-block-date" dateTime={publishedDate_na_dt}>{moment(new Date(publishedDate_na_dt)).format('DD MMMM, YYYY')}</time>) : (<span></span>)}
                    </div>
                </a>
            </div>
        </div>
    )
}

export default RTLNCategoryDocument
