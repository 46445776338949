'use strict';
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Utils} from '@bravo/react-search'

import App from './app'
import reducer from '../reducers/default'

import * as IDSAActions from '../actions/idsa/index'

export default function SearchTemplate({element, keys, telemetry}){
    Utils.store.createSearchStore(reducer, keys, {telemetry}).then(store => {

        const idsaSuppTopics = { suppBaseUri: keys['suppBaseUri'], suppApiUri: "api/bravo/categories/topic" }

        IDSAActions.idsaTopicsInitialize(store, idsaSuppTopics)

        ReactDOM.render(<Provider store={store}><App st={store} telemetry={telemetry} isMember={keys['isMember']} oupSsoPrefix={keys['oupSsoPrefix']} /></Provider>, element)
    })
}