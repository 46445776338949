import * as React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Search, Metrics, SearchActions, MetricsActions} from '@bravo/react-search'

import Documents from '../../components/Documents'
import ViewMore from '../../components/ViewMore'
import DropDown from '../../components/Facets/dropDown'
import RtlnSort from '../../components/RtlnSort'
import ResultsMessage from '../../components/ResultsMessage'
import NoResults from '../../components/NoResults'

import "../../styles/app.scss"

const mapStateToProps = (state, ownProps) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        searchActions: bindActionCreators(SearchActions, dispatch),
        metricsActions: bindActionCreators(MetricsActions, dispatch),
    }
}

const App = (props) => {

    // console.log("Redux Store/Actions:", props);

    const {search, searchActions, isMember, oupSsoPrefix, st, metrics, metricsActions} = props
    const {trackQueryEvent} = metricsActions

    const {ui, documents, keyword, paging, facets, sort} = search
    const {isFetching} = ui
    const hasDocuments = documents.list.length > 0

    const {onHashChange, refreshCancel, searchInitialize, searchSubmit} = searchActions

    const {onFacetClear, onFacetTermToggle, onPageNext} = searchActions
    const {onSortChange} = searchActions

    const bodyEl = React.useRef(null)
    React.useEffect(() => {
        //no auto-scroll on initial page load
        if(bodyEl.current.getBoundingClientRect().top < 0) {
            bodyEl.current.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"})
        }
    }, [documents]);
    return (
        <div ref={bodyEl} className={(isFetching) ? "bs-app loading" : "bs-app"}>
            <Metrics
                {...metrics}
                trackQueryEvent={trackQueryEvent}
            />
            <Search
                onHashChange={onHashChange}
                refreshCancel={refreshCancel}
                search={search}
                searchInitialize={searchInitialize}
                searchSubmit={searchSubmit}
                deepLinking={true}
                appendResults={true}
            />

            <div className="typeLinkItemBlockWrap">
                <div className="container idsaContainerBlock">

                    {(hasDocuments) ? (
                        <div className="bravoSearchResults">
                            <div className="results-header">
                                <ResultsMessage
                                    total={documents.total}
                                    searchTerm={keyword.searchTerm}
                                />
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex flex-row">
                                    <div className="bravoSearchResults_dd_selector">
                                        <RtlnSort
                                            sort={sort}
                                            onSortChange={onSortChange}
                                        />
                                    </div>
                                    {(facets.groups.length > 0 && 
                                    <div className="bravoSearchResults_dd_selector" style={{paddingLeft:15}}>
                                        <div className='d-flex flex-row flex-nowrap align-items-center'>
                                            <div className='flex-grow-0 flex-shrink-1'>
                                                <span className="sort-text">Filter By</span>
                                            </div>
                                            <div className='flex-grow-1 flex-shrink-1'>
                                                <DropDown
                                                    facets={facets}
                                                    onFacetTermToggle={onFacetTermToggle}
                                                    onFacetClear={onFacetClear}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    )}
                                </div>
                                <div className="col-12 search-columns">
                                    <Documents
                                        documents={documents}
                                        onDocumentClick={searchActions.onDocumentClick}
                                        isMember={isMember}
                                        isRTLN2={true}
                                        oupSsoPrefix={oupSsoPrefix}
                                        showImages={true}
                                    />
                                </div>
                                <div className="col-12">
                                    {(hasDocuments) && (
                                        <div className="row">
                                            <div className="col-12 paging-alt">
                                                <ViewMore
                                                    paging={paging}
                                                    onPageNext={onPageNext}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <NoResults
                                    st={st}
                                    searchInitialize={searchInitialize}
                                    searchSubmit={searchSubmit}
                                    isFetching={isFetching}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
