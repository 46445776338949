import React from 'react'
import PropTypes from 'prop-types'

import Facets from './index'
import Ranges from '../Ranges'
import { hideModals } from './utils'

import '../../styles/facets.scss'

const Modal = (props) => {
    const {facets, onFacetTermToggle, onFacetClear, ranges, onRangeChange, suppAggData, docCount} = props
    const hasFacetSelections = Object.keys(facets.selected).length > 0

    const clickFn = (evt) => {
        onFacetClear()
        hideModals()
    }

    return (
        <div>
            <div className="d-lg-none bravoSearchModalbtn">
                <button type="button" className="btn btn-link" data-toggle="modal" data-target="#bravoSearchModal_Facets"><i className="fa fa-filter"></i></button>
            </div>
            <div className="modal fade" data-backdrop="static" id="bravoSearchModal_Facets" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <i className="fa fa-filter"></i>
                            <span className="title">Filters</span>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div id="bravoSearchFacetPanel" className="facet-panel-wrap">
                                <div className="facets menu">
                                {(facets.groups.length > 1) && (
                                    <div className="col-lg-4 col-xl-3">
                                        <div id="bravoSearchFacetPanelLg" className="facet-panel-wrap">
                                            <div className="facets">
                                                <Facets
                                                    facets={facets}
                                                    onFacetTermToggle={onFacetTermToggle}
                                                    suppAggData={suppAggData}
                                                    docCount={docCount}
                                                />
                                                <Ranges 
                                                    onRangeChange={onRangeChange}
                                                    ranges={ranges}
                                                />
                                                {(hasFacetSelections) && (
                                                    <div className="clear-filters text-center">
                                                        <a onClick={clickFn} className="btn btn-primary search" data-feathr-click-track="true">Clear Filters</a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    facets: PropTypes.shape({
        selected: PropTypes.object,
        groups: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            items: PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ]),
                docCount: PropTypes.number,
                selected: PropTypes.bool,
                title: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ])
            })),
            title: PropTypes.string
        }))
    }).isRequired,
    onFacetTermToggle: PropTypes.func.isRequired,
    ranges: PropTypes.object.isRequired,
    onRangeChange: PropTypes.func.isRequired,
    onFacetClear: PropTypes.func.isRequired, 
    suppAggData: PropTypes.object.isRequired
}
export default Modal