import 'cross-fetch'

export default class IDSASupplementalApi{

    constructor(baseUrl = 'https://idsociety.org', path){
        this.baseUrl = baseUrl;
        this.path = path;
    }

    query(){
        return fetch(`${this.baseUrl}/${this.path}`, {
            method: "GET",
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
            })       
        }).then(response => {
           
            //console.log("IDSASupplementApi:fetch:then:response", response)

            switch (response.status) {
                 case 200:
                     return response.json()                   
                 default:
                     throw response
            }
        });
    }
}