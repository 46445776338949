import * as React from 'react'

function DocumentClick(props){
    const {onClick, children} = props
    return (
        <span onClick={onClick}>
            {children}
        </span>
    )
}

export default DocumentClick