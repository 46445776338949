import * as React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Search, Metrics, SearchActions, MetricsActions} from '@bravo/react-search'

import Documents from '../../components/Documents'
import NoResults from '../../components/NoResults'

import "../../styles/app.scss"

const mapStateToProps = (state, ownProps) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        searchActions: bindActionCreators(SearchActions, dispatch),
        metricsActions: bindActionCreators(MetricsActions, dispatch),
    }
}

const App = (props) => {

     console.log("Redux Store/Actions:", props);
     
    const {search, searchActions, idsaSuppAggData, searchBody, isMember, oupSsoPrefix, st, isrtln} = props
    const {ui, documents, facets, keyword, paging, ranges, settings, sort, tab} = search
    const {viewAllClicked} = settings
    const {pageSize} = settings.query;
    const {isFetching} = ui
    const hasDocuments = documents.list.length > 0

    const {onHashChange, refreshCancel, searchInitialize, searchSubmit} = searchActions

    const bodyEl = React.useRef(null)
    React.useEffect(() => {
        //no auto-scroll on initial page load
        if (bodyEl.current.getBoundingClientRect().top < 0) {
            bodyEl.current.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"})
        }
    }, [documents]);
    return (
        <div ref={bodyEl} className={(isFetching) ? "bs-app loading" : "bs-app"}>
            <Search 
                onHashChange={onHashChange}
                refreshCancel={refreshCancel}
                search={search}
                searchInitialize={searchInitialize}
                searchSubmit={searchSubmit}
                deepLinking={false}
            />            
            <div className="container">
                {(hasDocuments) ? (
                    <div className="bravoSearchResults">
                        <div className="row">                        
                            <div className="search-columns">
                                {searchBody && (
                                    <div className="copy-header" dangerouslySetInnerHTML={{__html: searchBody}}></div>
                                )}
                                
                                <Documents
                                    documents={documents}
                                    onDocumentClick={searchActions.onDocumentClick}
                                    isMember={isMember}
                                    oupSsoPrefix={oupSsoPrefix}
                                    isMediaTemplate={false}
                                    isBravoBlockListing={true}
                                    isrtln={isrtln}
                                    searchTerm={keyword.searchTerm}
                                />                                
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <NoResults 
                                st={st}
                                searchInitialize={searchInitialize}
                                searchSubmit={searchSubmit}
                                isFetching={isFetching}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
