import * as React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Search, Metrics, SearchActions, MetricsActions} from '@bravo/react-search'

import DropDown from '../../components/Facets/dropDown'
import FacetTabs from '../../components/Facets/tabs'
import Documents from '../../components/Documents'
import ViewMore from '../../components/ViewMore'

import "../../styles/app.scss"

const mapStateToProps = (state, ownProps) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        searchActions: bindActionCreators(SearchActions, dispatch),
        metricsActions: bindActionCreators(MetricsActions, dispatch),
    }
}

const App = (props) => {

    console.log("Redux Store/Actions:", props);

    let {search} = props
    const {searchActions, isMember, oupSsoPrefix} = props

    const {ui, documents, facets, paging, tab} = search
    const {isFetching} = ui
    const hasDocuments = documents.list.length > 0

    const {onHashChange, refreshCancel, searchInitialize, searchSubmit} = searchActions

    const {onPageNext, onFacetClear, onFacetTermToggle, onTabSelect, onTabClear} = searchActions

    const {metrics, metricsActions} = props
    const {trackQueryEvent} = metricsActions

    return (
        <div className={(isFetching) ? "bs-app loading" : "bs-app"}>
            <Metrics
                {...metrics}
                trackQueryEvent={trackQueryEvent}
            />
            <Search
                onHashChange={onHashChange}
                refreshCancel={refreshCancel}
                search={search}
                searchInitialize={searchInitialize}
                searchSubmit={searchSubmit}
                deepLinking={true}
                appendResults = {true}
            />

            <div className="typeLinkItemBlockWrap">
                <div className="container idsaContainerBlock">
                        <div className="bravoSearchResults">
                            <div className="row">
                                <div className="col-12">
                                    <div className="bravoSearchResults_dd_selector">
                                        <DropDown
                                            facets={facets}
                                            onFacetTermToggle={onFacetTermToggle}
                                            onFacetClear={onFacetClear}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 search-columns">
                                    <Documents
                                        documents={documents}
                                        onDocumentClick={searchActions.onDocumentClick}
                                        isMember={isMember}
                                        oupSsoPrefix={oupSsoPrefix}
                                        isRTLNCategoryPage={true}
                                        showImages={true}
                                    />
                                </div>
                                <div className="col-12">
                                    {(hasDocuments) && (
                                        <div className="row">
                                            <div className="col-12 paging-alt">
                                                <ViewMore
                                                    paging={paging}
                                                    onPageNext={onPageNext}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
