import * as React from 'react'
import PropTypes from 'prop-types'

import '../../styles/spellcheck.scss'

function Spellcheck(props){
    const {keyword, onKeywordChange, onKeywordSubmit} = props
    const {suggestions, searchTerm} = keyword
    if(suggestions && suggestions.terms.length > 0 && suggestions.text !== searchTerm){
        return (
            <div
                onClick={() => {onKeywordChange(suggestions.text); onKeywordSubmit()}}
                className="bs-suggest-group"
            >
                <span>
                    {"Did you mean "}
                        <i>{suggestions.text}</i>
                    {"?"}
                </span>
            </div>
        )
    }
    return null
}

Spellcheck.propTypes = {
    keyword: PropTypes.object.isRequired,
    onKeywordChange: PropTypes.func.isRequired,
    onKeywordSubmit: PropTypes.func.isRequired
}

export default Spellcheck