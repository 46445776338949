import React from 'react'

import { hideModal } from '../Facets/utils'

class DateRangeGroup extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            min: props.range.min,
            max: props.range.max
        }
        this.handleClear = this.handleClear.bind(this)
        this.handleMaxChange = this.handleMaxChange.bind(this)
        this.handleMinChange = this.handleMinChange.bind(this)
    }
    handleMinChange(evt){
        this.setState({min: evt.target.value})
    }
    handleMaxChange(evt){
        this.setState({max: evt.target.value})
    }
    handleClear(field){
        this.setState({min: '', max:''})
        this.props.onRangeChange(field, '', '')
    }
    render(){
        const {range, onRangeChange} = this.props
        const {min, max} = this.state
        return (
            <div className="panel panel-default facets-group date-range" id="accordion-date">
                <div className="panel-heading">
                    <span>Date</span>
                </div>
                <div id="collapseDate" className="panel-collapse collapse show">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-lg-6 date-range-sel">
                                <label htmlFor="datepicker-min">From</label>
                                <div className="date-range-sel-input">
                                <input 
                                    id={range.title}
                                    type="date" 
                                    value={min} 
                                    onChange={this.handleMinChange}
                                    style={{padding:'8px 6px 8px 6px'}}
                                />
                                </div>
                            </div>
                            <div className="col-lg-6 date-range-sel">
                                <label htmlFor="datepicker-max">To</label>
                                <div className="date-range-sel-input">
                                    <input 
                                        type="date" 
                                        value={max} 
                                        onChange={this.handleMaxChange}
                                        style={{padding:'8px 6px 8px 6px'}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row text-center justify-content-center">
                            <div className="col-8"><button type="button" className="btn btn-primary margin orange" onClick={() => {onRangeChange(range.id, min, max); hideModals()}}>Apply</button></div>
                        </div>
                        <div className="row text-center justify-content-center">
                            <div className="col-6"><button type="button" className="btn btn-link" onClick={() => {this.handleClear(range.id); hideModals()}}>Clear</button></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DateRangeGroup