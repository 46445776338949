import React from 'react'
import PropTypes from 'prop-types'

const NoResults = (props) => {
    const {st, searchInitialize, searchSubmit, isFetching} = props

    const resetTerm = (evt) => {
        evt.preventDefault()

        const search = st.getState().search
        
        const newSearch = {
            ...search,
            keyword: {
                highlights: [],
                searchTerm: "",
                suggestions: {terms: Array(0), text: ""},
                value: ""
            }
        }

        st.dispatch(searchInitialize(newSearch))
        searchSubmit(newSearch, {debugMode: false, pinned: false})
    }

    return (isFetching ? 
        (<div style={{textAlign: 'center'}}>
            <div className="spinner-border mx-auto mb-4" role="status" aria-hidden="true"></div>
            <p><strong>Loading...</strong></p>
        </div>)
        :
        (<div className="reset text-center">
            <h2>No results found</h2>
            <a className="btn btn-primary search" onClick={resetTerm}>Go Back</a>
        </div>)
    )
}

NoResults.propTypes = {
    searchInitialize: PropTypes.func,
    st: PropTypes.any.isRequired,
    searchSubmit: PropTypes.func.isRequired
}

export default NoResults