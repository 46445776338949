import React from 'react'
import PropTypes from 'prop-types'

import FacetTermGroup from './facetTermGroup'

import '../../styles/facets.scss'

const Facets = (props) => {
    const {facets, onFacetTermToggle, suppAggData, docCount} = props

    return (
        <div>
            {facets.groups.map(facet => {
                return (
                    <FacetTermGroup
                        facet={facet}
                        key={facet.id}
                        onFacetTermToggle={onFacetTermToggle}
                        size={5}
                        suppAggData={suppAggData}
                        docCount={docCount}
                    />
                )
            })}
        </div>
    )
}
Facets.propTypes = {
    facets: PropTypes.shape({
        selected: PropTypes.object,
        groups: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            items: PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ]),
                docCount: PropTypes.number,
                selected: PropTypes.bool,
                title: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ])
            })),
            title: PropTypes.string
        }))
    }).isRequired,
    onFacetTermToggle: PropTypes.func.isRequired,
    suppAggData: PropTypes.object.isRequired
}
export default Facets