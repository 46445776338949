import * as React from 'react'
import '../../styles/document.scss'

import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Search, Metrics, SearchActions, MetricsActions} from '@bravo/react-search'

import RTLNCategoryDocument from '../../components/Documents/rtlnCategoryDocument'

import "../../styles/app.scss"

const mapStateToProps = (state, ownProps) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        searchActions: bindActionCreators(SearchActions, dispatch),
        metricsActions: bindActionCreators(MetricsActions, dispatch),
    }
}

const App = (props) => {

    console.log("Redux Store/Actions:", props);

    let {search} = props
    const {searchActions, category, isMember, oupSsoPrefix, resourceId} = props

    const {ui, documents} = search
    const {isFetching} = ui
    const hasDocuments = documents.list.length > 0

    const {onHashChange, refreshCancel, searchInitialize, searchSubmit} = searchActions

    const {metrics, metricsActions} = props
    const {trackQueryEvent} = metricsActions

    const bindOwlCarousel = () => {
        $(".blogpage-related-carousel").owlCarousel({
            margin: 0,
            autoWidth: false,
            nav: true,
            navText: ['<span class="icon icon-arrow-left"></span>', '<span class="icon icon-arrow-right"></span>'],
            beforeInit: function (elem) {
                random(elem);
            },
            autoplay: false,
            dots: false,
            responsiveClass: true,
            loop: true,
            rewind: false,
            responsive: {
                0: { items: 1, slideBy: 1 }
            }
        });
    }
    React.useEffect(() => {
        bindOwlCarousel();
    }, [documents]);

    if(category)
    {
        const facets = search.facets;
        search = {
            ...search,
            facets: {
                ...facets,
                selected: {
                    rTLNCategories_na_str: [
                        category
                    ]
                }
            }
        }

        // console.log("Category id", category)
    }

    //remove the current page from the results, if present
    if(resourceId)
    {
        documents.list.forEach((element, index) => {
            if(element.page_Id_str === resourceId) {
                //console.log('removed document', resourceId)
                documents.list.splice(index, 1)
            }
        });
    }

    //if the current page was not in the returned results, remove the last document to make the remaining docs 6
    if(documents.list.length === 7) {
        documents.list.pop()
    }

    var $likeThisList = $(".blogpage-likethis-list")

    //only append the "more like this" results if the div exists (this feature can be turned of, thats why it might not be there)
    if($likeThisList) {
        //empty the area in case this component gets rendered multiple times
        $likeThisList.empty()

        if(documents.list && documents.list.length) {
            documents.list.slice(0,3).map((doc) => {
                $likeThisList.append(`<a href=\"${doc.resourceUrl ?? doc.url}\" class=\"blogpage-likethis-item${doc.isExternalUrl === "true" ? " isExternalLink" : ""}\" target=${doc.isExternalUrl === "true" ? "_blank" : "_self"}>${doc.title.trim()}</a>`)
            })
        }
    }

    return (
        <div id="blogpage-related" className={(isFetching) ? "bs-app loading" : "bs-app"}>
            <Metrics
                {...metrics}
                trackQueryEvent={trackQueryEvent}
            />
            <Search
                onHashChange={onHashChange}
                refreshCancel={refreshCancel}
                search={search}
                searchInitialize={searchInitialize}
                searchSubmit={searchSubmit}
                deepLinking={true}
                appendResults = {true}
            />
            {(hasDocuments) ? (
                <div className="blogpage-related-carousel owl-carousel owl-theme">
                    {documents.list.map((doc) => {
                        return <RTLNCategoryDocument
                            key={doc.id}
                            {...doc}
                            isMember={isMember}
                            oupSsoPrefix={oupSsoPrefix}
                            isCarouselItem="true"
                        />
                    })}
                </div>
            ) : (
                <div></div>
            )}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
