import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { getSourceDisplay } from '../Documents/utils'
import { hideModals } from './utils'

function GetEnhancedItem(item, itemList, flatSuppAggData, level) {

    if (flatSuppAggData.length === 0) {

        return {
            origItem: item,
            suppTitle: item.title,
            suppLevel: 0,
            suppChildEnhancedItems: []
        }
    }

    const matchingSuppAggItem = flatSuppAggData.find(suppAggItem => suppAggItem.Id == item.title)

    if(matchingSuppAggItem === undefined)
        return null

    const parentItem = itemList.find(i => i.title == matchingSuppAggItem.ParentId)

    if(parentItem !== undefined && !parentItem.selected)
        return null

    const suppTitle = !matchingSuppAggItem ? item.title : matchingSuppAggItem.Name
    const suppLevel = !matchingSuppAggItem ? 0 : matchingSuppAggItem.Level

    if (level !== undefined && suppLevel > level)
        return null
    
    const childItems = !matchingSuppAggItem || !item.selected 
        ? []
        : itemList
            .filter(i => flatSuppAggData.filter(suppAggItem => suppAggItem.ParentId == matchingSuppAggItem.Id)
                .map(suppAggItem => suppAggItem.Id).includes(''+i.title))

    const childEnhanchedItems = childItems.map(i => GetEnhancedItem(i, itemList, flatSuppAggData, level + 1))

    return {
        origItem: item,
        suppTitle: suppTitle,
        suppLevel: suppLevel,
        suppChildEnhancedItems: childEnhanchedItems
    }
}
 
const ReduceToEnhancedItems = (itemList, flatSuppAggData, level) => {

    return itemList.reduce((acc, item) => {
       
        const enhancedItem = GetEnhancedItem(item, itemList, flatSuppAggData, level)
        return acc.concat(!!enhancedItem ? enhancedItem : [])

    }, [])
}

const FlattenSpecificSuppAggData = (specificSuppAggData, parentId, level) => {

    if (specificSuppAggData === undefined)
        return []

    return specificSuppAggData.reduce((acc, sai) => {

        acc.push({ Name: sai.Name, Id: sai.Id, ParentId: parentId, Level: level })

        if (sai && sai.Children && sai.Children.length > 0)
            acc = acc.concat(FlattenSpecificSuppAggData(sai.Children, sai.Id, level + 1))

        return acc

    }, [])
}

function FacetTermItem(props) {

    const { enhancedItem, onFacetTermToggleEnhanced, isSource, docCount } = props

    //console.log("Doc count: " + docCount);

    const itemTitle = isSource ? getSourceDisplay(enhancedItem.suppTitle) : enhancedItem.suppTitle;
    //if a facet will not change the output of the search result and is not already checked, silently check it and disable
    const disabled = !enhancedItem.origItem.selected && enhancedItem.origItem.docCount === docCount;
    const checked = !!enhancedItem.origItem.selected || disabled;
    // console.log("Is source? " + isSource + ". Display title: " + itemTitle);

    const clickFn = (evt) => {
        onFacetTermToggleEnhanced(enhancedItem)
        hideModals()
    }

    if (enhancedItem.origItem.docCount === 0 && !enhancedItem.origItem.selected)
        return null

    return (
        <>
        <div className={clsx("facets-term", checked && "checked", disabled && "disabled", "hierarchy", "depth" + enhancedItem.suppLevel)}>
            <label>      
                <input readOnly disabled={disabled ? "disabled" : ""} type="checkbox" name="facetTerm" value={enhancedItem.origItem.title} className="input-facet-selection" checked={checked} onClick={clickFn} />         
                <div className="bs-filter-label">            
                    <span className="term">{itemTitle}</span>
                    <span className="filter-count"> ({enhancedItem.origItem.docCount})</span>                    
                </div>                                
            </label>
        </div>
        { enhancedItem.suppChildEnhancedItems.map(childItem => (<FacetTermItem key={childItem.origItem.key} enhancedItem={childItem} onFacetTermToggleEnhanced={onFacetTermToggleEnhanced} docCount={docCount} />)) }
        </>
    ) 
}

const FacetIdsOverrideShowToggle = [];
//11/17/2022 removed topics from override toggle at request of client for multimedia search, 
//topics were removed from the main search a while ago
//'topic_na_int', 'channels_na_str' 

function FacetTermGroup(props) {

    const { facet, onFacetTermToggle, suppAggData, docCount } = props
    const isSource = facet.title === 'Source';

    if (facet.items.length === 0)
        return null

    const isOverrideShowToggle = FacetIdsOverrideShowToggle.includes(facet.id)

    //rs: add a dedicated state for the expansion of the entire facet group
    const [groupExpanded, setGroupExpanded] = React.useState(true)
    const [expanded, setExpanded] = isOverrideShowToggle ? React.useState(true) : React.useState(false)

    let facetSize = props.size
    if(facet.items.length <= facetSize + 2)
        facetSize = facet.items.length

    const [size, setSize] = React.useState(facetSize || 0)

    //console.log("Facet size", size);

    const showToggle = isOverrideShowToggle ? false : (facetSize > 0 && facet.items.length > facetSize)

    const list = React.useMemo(() => {
        return (showToggle && !expanded) ?
            facet.items.slice(0, size) :
            facet.items
    }, [showToggle, expanded, facet.items])

    const hasSelections = React.useMemo(() => {
        return facet.items.map(i => i.selected).includes(true)
    }, [list])

    const clearAllSelectedTerms = () => {
        const list = facet && facet.items ? facet.items.filter(i => i.selected) : []
        onFacetTermToggle(list, facet)
    }

    //try to find supplemental data for this specific facet
    const flatSuppAggData = suppAggData[facet.id] === undefined
                            ? []
                            : FlattenSpecificSuppAggData(suppAggData[facet.id].list, undefined, 0)
    //console.log("Flattened Specific Supplemental Agg Data:", flatSuppAggData)

    const listEnhancedItems = ReduceToEnhancedItems(list, flatSuppAggData, 0)
    //console.log("Enhanced Items:", listEnhancedItems)

    //this overrides the default OnFacetTermToggle to automatically unselect child items of a parent when it becomes unselected
    const onFacetTermToggleEnhanced = (enhancedItem) => {
        const listChildItemsSelected = enhancedItem.suppChildEnhancedItems.filter(ei => ei.origItem.selected).map(ei => ei.origItem)
        onFacetTermToggle(listChildItemsSelected, facet)
        onFacetTermToggle(enhancedItem.origItem, facet)
    }

    return (
        <div className="panel panel-default facets-group">
            <div className="panel-heading">
                <a className={clsx(!groupExpanded && "collapsed")} aria-expanded={'' + groupExpanded} onClick={() => setGroupExpanded(!groupExpanded)}>
                    <span>{facet.title}</span>
                    <div className="panel-heading-icon fa fa-minus"></div>
                </a>
            </div>
            <div className={clsx("panel-collapse", "collapse", groupExpanded && "show")}>
                <div className={clsx("panel-body", (expanded) && 'expanded', (showToggle) && "forgive")}>
                    <form>
                        { listEnhancedItems.map(enhancedItem => (<FacetTermItem key={enhancedItem.origItem.key} enhancedItem={enhancedItem} onFacetTermToggleEnhanced={onFacetTermToggleEnhanced} isSource={isSource} docCount={docCount} />)) }
                        {(showToggle) && (
                            expanded ? (
                                <div className="bbSearchFacetItem facet-group-show-less text-center">
                                    <a className="btn btn-link small" onClick={() => setExpanded(!expanded)}>Load Less</a>
                                </div>
                            ) : (
                                <div className="bbSearchFacetItem facet-group-show-more text-center">
                                    <a className="btn btn-link small" onClick={() => setExpanded(!expanded)}>Load More {`(${facet.items.length - size})`}</a>
                                </div>
                            )
                        )}
                    </form>
                </div>
            </div>
        </div>
    )
}

FacetTermGroup.propTypes = {
    size: PropTypes.number,
    facet: PropTypes.object.isRequired,
    onFacetTermToggle: PropTypes.func.isRequired,
    suppAggData: PropTypes.object.isRequired
}

export default FacetTermGroup
