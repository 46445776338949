import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const RtlnSort = (props) => {
    const {sort, onSortChange} = props
    const {selected} = sort
    const sortClick = (evt) => {
        evt.preventDefault();
        const [id, dir] = evt.target.dataset.brsvalue.split('/')

        onSortChange({id, dir})
    }
    if(sort.options.length <= 1){
        return null
    }
    return (
        <div className='d-flex flex-row flex-nowrap align-items-center'>
            <div className='flex-grow-0 flex-shrink-1'>
                <span className="sort-text">Sort By</span>
            </div>
            <div className='flex-grow-1 flex-shrink-1'>
                <div className="btn-group" role="group">
                    <button id="btnRtlnSort" type="button" className="btn btn-group-drop dropdown-toggle d-flex flex-nowrap justify-content-between align-items-center text-left" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className='d-flex flex-column'>
                            <span>{sort.options.find(o => o.id === sort.selected.id && o.dir === sort.selected.dir).title}</span>
                            {sort.options.map((opt, i) => {
                                return (
                                    <span key={i} className='btn-group-drop-opt' style={{height: "0", opacity: "0", overflow: "hidden"}}>{opt.title}</span>
                                )
                            })}
                        </span>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="btnRtlnSort">
                        {sort.options.map((opt, i) => {
                            return (
                                <a href="#" key={i} className={clsx("dropdown-item", (opt.id === sort.selected.id && opt.dir === sort.selected.dir) && "selected")} data-brsvalue={`${opt.id}/${opt.dir}`} onClick={sortClick}>{opt.title}</a>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
RtlnSort.propTypes = {
    sort: PropTypes.shape({
        selected: PropTypes.shape({
            id: PropTypes.string,
            dir: PropTypes.string,
            title: PropTypes.string
        }),
        options: PropTypes.array
    }).isRequired,
    onSortChange: PropTypes.func.isRequired
}
export default RtlnSort