import * as IDSAActionTypes from './actionTypes'
import IDSASupplementalApi from './idsaSupplementalApi'

const getSupplementalApi = (supp) =>{
    const {suppBaseUri, suppApiUri} = supp
    return new IDSASupplementalApi(suppBaseUri, suppApiUri)
}

export const idsaTopicsInitialize = (store, supp) => {
    
    // console.log("IDSA Initialize Topics.", supp)

    const api = getSupplementalApi(supp)
    const promise = api.query()
    
    promise.then(response => {
        store.dispatch({

            type: IDSAActionTypes.IDSA_TOPICS_REQUEST_COMPLETE,
            error: false,
            payload: {
                response
            }
        })
    }).catch(error => {
        store.dispatch({
            type: IDSAActionTypes.IDSA_TOPICS_REQUEST_COMPLETE,
            error: true,
            payload: {
                error
            }
        })
    })
}
